import styled from "styled-components";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const RoomsTable = ({ data }) => {
  const { t } = useTranslation();
  const rooms = Array.isArray(data) ? data : [data];

  return (
    <Table>
      <thead>
        <TableHeader>
          <FirstHeaderItem>{t("voucherRooms")} :</FirstHeaderItem>
          <HeaderItem></HeaderItem>
          <HeaderItem></HeaderItem>
          <HeaderItem></HeaderItem>
        </TableHeader>
      </thead>
      <tbody>
      {rooms.map((item, index) => (
          <BodyTr key={`room-${index}`}>
            <BodyTd>{item.Title}</BodyTd>
            <BodyTd>
              {t("voucherAdults")} {item.Adult}
            </BodyTd>
            <BodyTd>
              {t("voucherChildren")} {item.Child}
            </BodyTd>
            <BodyTd>
              {t("voucherWith")} {item.Boarding.Title}
            </BodyTd>
          </BodyTr>
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border: 1px solid ${colors.black15};
  margin: 10px 0;
  font-size: 14px;
`;

const TableHeader = styled.tr`
  color: white;
`;

const BodyTr = styled.tr`
  border-bottom: 1px solid ${colors.black15};
`;

const FirstHeaderItem = styled.th`
  background-color: ${colors.gold};
  padding: 10px 20px;
  flex: 3;
`;
const HeaderItem = styled.th`
  background-color: ${colors.black35};
  padding: 10px 20px;
  flex: 1;
`;

const BodyTd = styled.td`
  padding: 10px 20px;
`;

export default RoomsTable;
